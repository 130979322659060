import { BaseGetParams, CreateSubscriptionResponse, GetUserResponse, GetUsersResponse } from "@interfaces/";
import apiClient from "./ApiClient";

export const conversationsByUser = async (orgId: string, userId: string, role: string, token: string) => {
  return await apiClient.get(`${orgId}/conversation/?user_id=${userId}`, role, undefined, token, true);
};

export const getUsersStats = async (orgId: string, role: string, payload: any, token: string) => {
  return await apiClient.post(`user/${orgId}/user_stat/`, role, payload, undefined, token, true);
};

export const inviteUser = async (orgId: string, role: string, payload: any, token: string) => {
  return await apiClient.post(`${orgId}/user/invite`, role, payload, undefined, token as string, true);
};

export const getUsers = async (orgId: string, role: string, token: string): Promise<GetUsersResponse> => {
  return await apiClient.get(`${orgId}/user/`, role, undefined, token, true);
};

export const resendInviteLink = async (orgId: string, userId: string, role: string, payload: any, token: string) => {
  return await apiClient.post(`${orgId}/user/${userId}/resend_invite_link`, role, payload, undefined, token, false);
};

export const getUserRoles = async (orgId: string, role: string, token: string) => {
  return await apiClient.get(`${orgId}/role/`, role, undefined, token, true);
};

interface getUserParams extends BaseGetParams {
  userId: string;
}

export const getUser = async ({
  orgId,
  role,
  token,
  userId,
  showToast = true,
}: getUserParams): Promise<GetUserResponse> => {
  return await apiClient.get(`${orgId}/user/${userId}`, role, undefined, token, showToast);
};

export const getMagicLink = async (orgId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/user/signin`, role, payload, undefined, undefined, true);
};

export const verifyUser = async (orgId: string, userId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/user/${userId}/verify`, role, payload, undefined, undefined, true);
};

interface UpdateUserPayload {
  enable_response_recommendation?: boolean;
  first_name?: string;
  last_name?: string;
}

export async function updateUser(params: { orgId: string; userId: string; payload: UpdateUserPayload; token: string }) {
  const { orgId, userId, payload, token } = params;
  return await apiClient.post(`${orgId}/user/${userId}/user`, "Admin", payload, undefined, token, true);
}

export const createSubscription = async (
  orgId: string,
  userId: string,
  role: string,
  token: string,
  source: any,
  plan_id: string,
): Promise<CreateSubscriptionResponse> => {
  try {
    const response = await apiClient.post<CreateSubscriptionResponse>(
      `${orgId}/user/${userId}/subscription`,
      role,
      { source, plan_id },
      undefined,
      token,
      true,
    );
    return response;
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
};

interface ApiResponse {
  status: number;
  data?: any;
}

export const cancelSubscription = async (
  orgId: string,
  userId: string,
  subscriptionId: string,
  role: string,
  token: string,
): Promise<void> => {
  try {
    const response: ApiResponse = await apiClient.delete(
      `${orgId}/user/${userId}/subscription/${subscriptionId}`,
      role,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      undefined,
      true,
    );

    if (response.status === 204) {
      console.log("Subscription cancelled successfully");
    } else {
      console.error("Failed to cancel subscription", response);
      throw new Error("Failed to cancel subscription");
    }
  } catch (error) {
    console.error("Error cancelling subscription:", error);
    throw error;
  }
};
